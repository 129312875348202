// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card{
    /* border: 1px solid white; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* height: 480px;   */
    margin-top: 20%;
    width: 100%;
    margin: auto;

}

.card:hover{
   transform: translateY(-4px);
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
}`, "",{"version":3,"sources":["webpack://./src/Component/PricingBox/PricingBox.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,4CAA4C;IAC5C,qBAAqB;IACrB,eAAe;IACf,WAAW;IACX,YAAY;;AAEhB;;AAEA;GACG,2BAA2B;IAC1B,uBAAuB;IACvB,4CAA4C;AAChD","sourcesContent":[".card{\n    /* border: 1px solid white; */\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    /* height: 480px;   */\n    margin-top: 20%;\n    width: 100%;\n    margin: auto;\n\n}\n\n.card:hover{\n   transform: translateY(-4px);\n    border: 1px solid white;\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
