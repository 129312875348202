// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../DSA/DSA_Images/dsa-login-5.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dsa-loan {
    /* background-color: rgb(42, 42, 85); */
    border-style: double;
    /* border-width: thin; */
    border-radius: 0.3rem;
    /* padding: 30px; */
    /* margin: 0,auto; */
    margin-right: 10px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    height: 300px;
    background-repeat: no-repeat;
    color: white;

}

.dsa-login-div {
    background-color: #2492e8;
    padding: 0px;
    color: white;
    height: 300px;
    border-style: solid;
    border-radius: 0.3rem;
    margin-right: 10px;
    height: 300px;
    background-repeat: no-repeat;
    padding: 10px;
    /* border-color: black; */
}
.custom-modal {
    position: fixed;
    /* top: 10%; */
    right: 1%;
    width: 300px;
    text-align: center;
    z-index: 1050;
}

.custom-modal .modal-content {
    border-radius: 10px;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/DSA/DSA-login.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,oBAAoB;IACpB,wBAAwB;IACxB,qBAAqB;IACrB,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;IAClB,yDAA0D;IAC1D,sBAAsB;IACtB,aAAa;IACb,4BAA4B;IAC5B,YAAY;;AAEhB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,4BAA4B;IAC5B,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,cAAc;IACd,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".dsa-loan {\n    /* background-color: rgb(42, 42, 85); */\n    border-style: double;\n    /* border-width: thin; */\n    border-radius: 0.3rem;\n    /* padding: 30px; */\n    /* margin: 0,auto; */\n    margin-right: 10px;\n    background-image: url('../DSA/DSA_Images/dsa-login-5.jpg');\n    background-size: cover;\n    height: 300px;\n    background-repeat: no-repeat;\n    color: white;\n\n}\n\n.dsa-login-div {\n    background-color: #2492e8;\n    padding: 0px;\n    color: white;\n    height: 300px;\n    border-style: solid;\n    border-radius: 0.3rem;\n    margin-right: 10px;\n    height: 300px;\n    background-repeat: no-repeat;\n    padding: 10px;\n    /* border-color: black; */\n}\n.custom-modal {\n    position: fixed;\n    /* top: 10%; */\n    right: 1%;\n    width: 300px;\n    text-align: center;\n    z-index: 1050;\n}\n\n.custom-modal .modal-content {\n    border-radius: 10px;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
