// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .custom-first-div-dropdown {
    /* background-color: burlywood !important;  */
    /* float: right; */
    /* bottom: 10; */
    /* position: relative; */

  }
  .dsa-First-div-container{
    /* background-color: rgb(187, 189, 189); */
  }`, "",{"version":3,"sources":["webpack://./src/DSA/DSA_Dashboard/First_Div.css"],"names":[],"mappings":";EACE;IACE,6CAA6C;IAC7C,kBAAkB;IAClB,gBAAgB;IAChB,wBAAwB;;EAE1B;EACA;IACE,0CAA0C;EAC5C","sourcesContent":["\n  .custom-first-div-dropdown {\n    /* background-color: burlywood !important;  */\n    /* float: right; */\n    /* bottom: 10; */\n    /* position: relative; */\n\n  }\n  .dsa-First-div-container{\n    /* background-color: rgb(187, 189, 189); */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
