// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.uks-log{
    /* background-image: url('../../Indexpage//Images/img123.png'); */
    /* background-repeat: no-repeat; */
    /* background-position: right; */
    color: white;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color:#d1d2d3;  

}
.employe-login-div{
    background-color: #175493;

    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 320px;
    border-radius: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/DashBoard/Dashbordlogin/Employee_Login.css"],"names":[],"mappings":";AACA;IACI,iEAAiE;IACjE,kCAAkC;IAClC,gCAAgC;IAChC,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,wBAAwB;;AAE5B;AACA;IACI,yBAAyB;;IAEzB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,qBAAqB;AACzB","sourcesContent":["\n.uks-log{\n    /* background-image: url('../../Indexpage//Images/img123.png'); */\n    /* background-repeat: no-repeat; */\n    /* background-position: right; */\n    color: white;\n    justify-content: center;\n    align-items: center;\n    height: 600px;\n    background-color:#d1d2d3;  \n\n}\n.employe-login-div{\n    background-color: #175493;\n\n    padding: 10px;\n    padding-left: 20px;\n    padding-right: 20px;\n    height: 320px;\n    border-radius: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
