// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Layout.css */
.main-content {
    margin-top: 70px; /* Adjust based on your navbar height */
    padding: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Customer/Navbar/Layout.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,gBAAgB,EAAE,uCAAuC;IACzD,aAAa;EACf","sourcesContent":["/* Layout.css */\n.main-content {\n    margin-top: 70px; /* Adjust based on your navbar height */\n    padding: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
