// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.New-Customer-container-first {
    background-color: rgb(241, 243, 248);
    padding: 20px;
    padding-top: 60px;
    transition: margin-left 0.5s ease;
  }
  
  .New-Customer-container-first.sidebar-expanded {
    margin-left: 250px;
  }
  
  .New-Customer-container-second {
    background-color: white;
    height: auto;
    padding: 20px;
    border-radius: 0.5rem;
    margin-left: 0px !important;
    max-width: 98% !important;
  }
  
  .New-customer-head {
    font-size: 15px;
    font-weight: 600;
  }
  
  .customer-sentence {
    padding: 10px;
  }
  
  .Row1 {
    margin-top: 2.5%;
  }
  
  .radio-btn {
    margin: 4px;
    color: orangered;
    border-style: solid;
  }
  
  .error {
    color: red;
  }
  
  .New-customer-submit-button {
    margin-top: 20px;
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(89, 89, 250);
    margin-bottom: 10px;
  }
  
  @media(max-width: 992px) {
    .New-Customer-container-first.sidebar-expanded {
      margin-left: 50%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/DSA/New-Customer.css"],"names":[],"mappings":";;AAEA;IACI,oCAAoC;IACpC,aAAa;IACb,iBAAiB;IACjB,iCAAiC;EACnC;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,2BAA2B;IAC3B,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,kCAAkC;IAClC,mBAAmB;EACrB;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":["\n\n.New-Customer-container-first {\n    background-color: rgb(241, 243, 248);\n    padding: 20px;\n    padding-top: 60px;\n    transition: margin-left 0.5s ease;\n  }\n  \n  .New-Customer-container-first.sidebar-expanded {\n    margin-left: 250px;\n  }\n  \n  .New-Customer-container-second {\n    background-color: white;\n    height: auto;\n    padding: 20px;\n    border-radius: 0.5rem;\n    margin-left: 0px !important;\n    max-width: 98% !important;\n  }\n  \n  .New-customer-head {\n    font-size: 15px;\n    font-weight: 600;\n  }\n  \n  .customer-sentence {\n    padding: 10px;\n  }\n  \n  .Row1 {\n    margin-top: 2.5%;\n  }\n  \n  .radio-btn {\n    margin: 4px;\n    color: orangered;\n    border-style: solid;\n  }\n  \n  .error {\n    color: red;\n  }\n  \n  .New-customer-submit-button {\n    margin-top: 20px;\n    padding: 7px;\n    padding-left: 20px;\n    padding-right: 20px;\n    background-color: rgb(89, 89, 250);\n    margin-bottom: 10px;\n  }\n  \n  @media(max-width: 992px) {\n    .New-Customer-container-first.sidebar-expanded {\n      margin-left: 50%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
