// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container styling */
.h-section-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-left: 0%;
}

/* Card styling */
.h-cardthird {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    margin: 10px;
    transition: transform 0.3s ease-in-out;
}



.h-peoplethird {
    background-color: #f8f9fa;
    border-radius: 50%;
    padding: 10px;
    display: inline-block;
    margin-bottom: 15px;
}

.MdPeoplethird {
    color: #007bff;
    font-size: 2rem;
}


.h-amountthird h1 {
    font-size: 2.5rem;
    margin: 0;
    color: #343a40;
}

.h-amountthird p {
    font-size: 1rem;
    margin: 0;
    color: #6c757d;
}
`, "",{"version":3,"sources":["webpack://./src/DashBoard/DashBoardDesign/Header_SectionThird.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB;;AAEA,iBAAiB;AACjB;IACI,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,sCAAsC;AAC1C;;;;AAIA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;;AAGA;IACI,iBAAiB;IACjB,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,cAAc;AAClB","sourcesContent":["/* Container styling */\n.h-section-3 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 20px 0;\n    margin-left: 0%;\n}\n\n/* Card styling */\n.h-cardthird {\n    background-color: #fff;\n    border: none;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    padding: 20px;\n    margin: 10px;\n    transition: transform 0.3s ease-in-out;\n}\n\n\n\n.h-peoplethird {\n    background-color: #f8f9fa;\n    border-radius: 50%;\n    padding: 10px;\n    display: inline-block;\n    margin-bottom: 15px;\n}\n\n.MdPeoplethird {\n    color: #007bff;\n    font-size: 2rem;\n}\n\n\n.h-amountthird h1 {\n    font-size: 2.5rem;\n    margin: 0;\n    color: #343a40;\n}\n\n.h-amountthird p {\n    font-size: 1rem;\n    margin: 0;\n    color: #6c757d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
