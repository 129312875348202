// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.uksregisterimage{
    /* background-image: url('../../Indexpage//Images/img123.png');
    background-repeat: no-repeat; */
   
}
.uks-reg{
    background-color: #175493;
    /* background-position: right; */
    color: white;
    justify-content: center;
    align-items: center;
    height: 600px;

}
.uks-h1 {
    text-align: center;
    margin-top: 50px;
}

.uks-form {
    margin-top: 30px;
}

.uks-routing {
    text-align: center;
    margin-top: 20px;
}

@media (max-width: 768px) {
    /* Add responsive styles here */
    .uks-h1 {
        font-size: 24px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/DashBoard/Dashbordlogin/Employee_Register.css"],"names":[],"mappings":";AACA;IACI;mCAC+B;;AAEnC;AACA;IACI,yBAAyB;IACzB,gCAAgC;IAChC,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;;AAEjB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B;QACI,eAAe;IACnB;AACJ","sourcesContent":["\n.uksregisterimage{\n    /* background-image: url('../../Indexpage//Images/img123.png');\n    background-repeat: no-repeat; */\n   \n}\n.uks-reg{\n    background-color: #175493;\n    /* background-position: right; */\n    color: white;\n    justify-content: center;\n    align-items: center;\n    height: 600px;\n\n}\n.uks-h1 {\n    text-align: center;\n    margin-top: 50px;\n}\n\n.uks-form {\n    margin-top: 30px;\n}\n\n.uks-routing {\n    text-align: center;\n    margin-top: 20px;\n}\n\n@media (max-width: 768px) {\n    /* Add responsive styles here */\n    .uks-h1 {\n        font-size: 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
