import React from 'react'
import Firstpage from './Firstpage/Firstpage'
import Index_Packages from './PricingBox/Index_Packages'

const AllComponentFlie = () => {
  return (
  
<div>
    <Firstpage />    
    <Index_Packages/>
</div>
  )
}

export default AllComponentFlie