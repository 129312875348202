// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: 20px;
    margin-left: 10px;
}

.Customer-dash-second {
    padding: 15px;
    border: 1px solid #ccc;
    background-color: white;
    text-align: center;
    border-radius: 0.3rem;  
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 0.3rem 2rem rgba(162, 160, 162, 0.5);
    height: 250px;
}

.Customer-dash-second:hover {
    background-color: #e0e0e0;
    transform: translateY(-5px);
}

.vertical-box {
    margin-top: 10px;
}

.vertical-box div {
    margin: 5px 0;
    padding: 5px;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease;
}

.vertical-box div:hover {
    color: #007bff;
}
.vertical-small-font{
    font-size: 11px;
}

@media (max-width: 768px) {
    .left-sidebar {
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .Customer-dash-second {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Customer_Dashboard/Vertical_Box.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;IACrB,2DAA2D;IAC3D,kDAAkD;IAClD,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;AACA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,cAAc;QACd,eAAe;QACf,mBAAmB;IACvB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".left-sidebar {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin-right: 20px;\n    margin-left: 10px;\n}\n\n.Customer-dash-second {\n    padding: 15px;\n    border: 1px solid #ccc;\n    background-color: white;\n    text-align: center;\n    border-radius: 0.3rem;  \n    transition: background-color 0.3s ease, transform 0.3s ease;\n    box-shadow: 0 0.3rem 2rem rgba(162, 160, 162, 0.5);\n    height: 250px;\n}\n\n.Customer-dash-second:hover {\n    background-color: #e0e0e0;\n    transform: translateY(-5px);\n}\n\n.vertical-box {\n    margin-top: 10px;\n}\n\n.vertical-box div {\n    margin: 5px 0;\n    padding: 5px;\n    text-decoration: underline;\n    cursor: pointer;\n    transition: color 0.3s ease;\n}\n\n.vertical-box div:hover {\n    color: #007bff;\n}\n.vertical-small-font{\n    font-size: 11px;\n}\n\n@media (max-width: 768px) {\n    .left-sidebar {\n        flex: 0 0 100%;\n        margin-right: 0;\n        margin-bottom: 20px;\n    }\n\n    .Customer-dash-second {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
