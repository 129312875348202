// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-navibar {
    background-color: rgb(255, 255, 255);
    margin-top: 0;
    margin-bottom: 0px;
    position: sticky;
  top: 0;
  z-index: 1030; /* Ensure it stays on top of other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.index-img1 {
    padding: 0;
    height: 55px;
}

.index-navname {
    font-size: 25px;
    font-weight: 500;
}

.index-navmenu {
    font-size: large;
}

.index-navbt {
    color: #6a727d;
    border-radius: 3rem;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    max-width: 300px;
    width: 120px;
}

.index-navbt:hover {
    background-color: #175493;
    color: white;
    text-decoration: none; 
}

.contact {
    background-color: #2391e6;
    color: white;
    border: none;
}

/* Media Queries */
@media (max-width: 800px) {
    .index-navbt {
        width: 25%;
        margin-bottom: 5px;
        text-align: end;
    }
}

@media (min-width: 1000px) and (max-width: 1024px) {
    .index-navbt {
        width: 11%;
        margin-bottom: 5px;
    }
}

@media (max-width: 1024px) {
    .index-navmenu {
        align-items: end;
        justify-content: end;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Indexpage/Navbar/Index-Navbar.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,aAAa;IACb,kBAAkB;IAClB,gBAAgB;EAClB,MAAM;EACN,aAAa,EAAE,6CAA6C;EAC5D,wCAAwC,EAAE,kCAAkC;AAC9E;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB;;AAEA,kBAAkB;AAClB;IACI;QACI,UAAU;QACV,kBAAkB;QAClB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,oBAAoB;IACxB;AACJ","sourcesContent":[".index-navibar {\n    background-color: rgb(255, 255, 255);\n    margin-top: 0;\n    margin-bottom: 0px;\n    position: sticky;\n  top: 0;\n  z-index: 1030; /* Ensure it stays on top of other elements */\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */\n}\n\n.index-img1 {\n    padding: 0;\n    height: 55px;\n}\n\n.index-navname {\n    font-size: 25px;\n    font-weight: 500;\n}\n\n.index-navmenu {\n    font-size: large;\n}\n\n.index-navbt {\n    color: #6a727d;\n    border-radius: 3rem;\n    font-size: 14px;\n    text-align: center;\n    text-decoration: none;\n    max-width: 300px;\n    width: 120px;\n}\n\n.index-navbt:hover {\n    background-color: #175493;\n    color: white;\n    text-decoration: none; \n}\n\n.contact {\n    background-color: #2391e6;\n    color: white;\n    border: none;\n}\n\n/* Media Queries */\n@media (max-width: 800px) {\n    .index-navbt {\n        width: 25%;\n        margin-bottom: 5px;\n        text-align: end;\n    }\n}\n\n@media (min-width: 1000px) and (max-width: 1024px) {\n    .index-navbt {\n        width: 11%;\n        margin-bottom: 5px;\n    }\n}\n\n@media (max-width: 1024px) {\n    .index-navmenu {\n        align-items: end;\n        justify-content: end;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
