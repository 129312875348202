// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-box {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    max-width: 300px;
}

h4 {
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
    margin-right: 10px;
    margin-top: -5px;
}

.checkbox-container label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.filter-count {
    margin-left: 5px;
    color: #666;
}

.filter-button {
    margin-top: 20px;
}

.button-link {
    cursor: pointer;
    color: #007bff;
    background: none;
    border: none;
    padding: 0;
    font-size: 16px;
    text-decoration: underline;
}
.Filter-head{
    font-size: 16px;
    margin: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/Outer_Customer_List/CustomerFilter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;AACA;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":[".filter-box {\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n    max-width: 300px;\n}\n\nh4 {\n}\n\n.checkbox-container {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.checkbox-container input[type=\"checkbox\"] {\n    margin-right: 10px;\n    margin-top: -5px;\n}\n\n.checkbox-container label {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n\n.filter-count {\n    margin-left: 5px;\n    color: #666;\n}\n\n.filter-button {\n    margin-top: 20px;\n}\n\n.button-link {\n    cursor: pointer;\n    color: #007bff;\n    background: none;\n    border: none;\n    padding: 0;\n    font-size: 16px;\n    text-decoration: underline;\n}\n.Filter-head{\n    font-size: 16px;\n    margin: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
