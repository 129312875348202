// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstpageh1{
    margin-top: 15%;
}

.firstpageh1 h1{
    width: 90%;
    margin-left: 6%;
    font-weight: 700;
}
.firstpageh1 p{
    width: 90%;
    margin-top: 4%;
    margin-left: 6%;
}

.firstpageh1 img{
    width: 5%;
    margin-top: 5%;
    margin-left: 6%;
}
.firstpageh1 Button{
    /* width: 20%; */
    padding: 18px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 4%;
    margin-left: 6%;
}

.firstpage{
    background-color: #175493;
    margin-top: 3%;
    /* height: 750px;    */
    width: 100%;
    height: auto;
    /* margin: auto; */
    padding: 0px !important;
    margin: 0px !important;
    align-items: center;
    color: white;
}

.firstpage2{
    /* border-radius: 50% 50% 0% 0%; */
    /* background-image: url('./loan-5.webp'); */
    background-repeat: no-repeat;
    /* height: 500px; */
    background-size: cover;
}

.firsthimg{
      /* height: 500px;   */
      width: 90%;
}
.getbutton{
    margin: 20px;
}

@media(min-width:1024px){
    .firstpage{
        height: 600px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Component/Firstpage/Firstpage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,cAAc;IACd,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,4CAA4C;IAC5C,4BAA4B;IAC5B,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;MACM,qBAAqB;MACrB,UAAU;AAChB;AACA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".firstpageh1{\n    margin-top: 15%;\n}\n\n.firstpageh1 h1{\n    width: 90%;\n    margin-left: 6%;\n    font-weight: 700;\n}\n.firstpageh1 p{\n    width: 90%;\n    margin-top: 4%;\n    margin-left: 6%;\n}\n\n.firstpageh1 img{\n    width: 5%;\n    margin-top: 5%;\n    margin-left: 6%;\n}\n.firstpageh1 Button{\n    /* width: 20%; */\n    padding: 18px;\n    padding-left: 30px;\n    padding-right: 30px;\n    margin-top: 4%;\n    margin-left: 6%;\n}\n\n.firstpage{\n    background-color: #175493;\n    margin-top: 3%;\n    /* height: 750px;    */\n    width: 100%;\n    height: auto;\n    /* margin: auto; */\n    padding: 0px !important;\n    margin: 0px !important;\n    align-items: center;\n    color: white;\n}\n\n.firstpage2{\n    /* border-radius: 50% 50% 0% 0%; */\n    /* background-image: url('./loan-5.webp'); */\n    background-repeat: no-repeat;\n    /* height: 500px; */\n    background-size: cover;\n}\n\n.firsthimg{\n      /* height: 500px;   */\n      width: 90%;\n}\n.getbutton{\n    margin: 20px;\n}\n\n@media(min-width:1024px){\n    .firstpage{\n        height: 600px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
