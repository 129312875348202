// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-button{
    display: flex;
    justify-content: flex-end;
    margin-right: 2.2%;

}

.overview{
    font-weight: 500;
    font-size: 25px;
    margin-left: 2.2%;
}
/* .dropdownbtn{
 
} */

.h-card{
    display: flex ;
    border: 1px solid white;
    box-shadow: grey 0.2px 0px 2px 0px;
}

.h-people{
margin: 7% 0% 0% 10%;
}

.h-section-2{
    width: 98%;
    margin-top: 1%;
    margin-left: 1%;
}
.MdPeople{
    background-color: rgb(200, 211, 211);
    height: 50px;
    padding: 5px;
    width: 50px;
    border-radius: 55% !important;
}

.MdPeople:hover{
    background-color: rgb(169, 175, 175);
    height: 50px;
    padding: 5px;
    width: 50px;
    border-radius: 55% !important;
}

.h-amount{
margin-left: 10%;

}

.h-amount h1 {
   transform: translateY(10px);
}`, "",{"version":3,"sources":["webpack://./src/DashBoard/DashBoardDesign/Header_Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;;AAEtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;;GAEG;;AAEH;IACI,cAAc;IACd,uBAAuB;IACvB,kCAAkC;AACtC;;AAEA;AACA,oBAAoB;AACpB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,eAAe;AACnB;AACA;IACI,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,oCAAoC;IACpC,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,6BAA6B;AACjC;;AAEA;AACA,gBAAgB;;AAEhB;;AAEA;GACG,2BAA2B;AAC9B","sourcesContent":[".header-button{\n    display: flex;\n    justify-content: flex-end;\n    margin-right: 2.2%;\n\n}\n\n.overview{\n    font-weight: 500;\n    font-size: 25px;\n    margin-left: 2.2%;\n}\n/* .dropdownbtn{\n \n} */\n\n.h-card{\n    display: flex ;\n    border: 1px solid white;\n    box-shadow: grey 0.2px 0px 2px 0px;\n}\n\n.h-people{\nmargin: 7% 0% 0% 10%;\n}\n\n.h-section-2{\n    width: 98%;\n    margin-top: 1%;\n    margin-left: 1%;\n}\n.MdPeople{\n    background-color: rgb(200, 211, 211);\n    height: 50px;\n    padding: 5px;\n    width: 50px;\n    border-radius: 55% !important;\n}\n\n.MdPeople:hover{\n    background-color: rgb(169, 175, 175);\n    height: 50px;\n    padding: 5px;\n    width: 50px;\n    border-radius: 55% !important;\n}\n\n.h-amount{\nmargin-left: 10%;\n\n}\n\n.h-amount h1 {\n   transform: translateY(10px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
