// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../DSA/DSA_Images/reg-dsa.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dsa-index-bg{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    height: 470px;
    background-size: cover;
    border-radius: 0.5rem;
    /* margin-right: 10px; */
}
.dsa-login-container{
    height: 605px;
    align-items: center;
}
.error-message {
    color: red !important;
    font-size: 0.9em;
    margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/DSA/DSA_register.css"],"names":[],"mappings":"AAAA;IACI,yDAAsD;IACtD,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".dsa-index-bg{\n    background-image: url('../DSA/DSA_Images/reg-dsa.jpg');\n    background-repeat: no-repeat;\n    height: 470px;\n    background-size: cover;\n    border-radius: 0.5rem;\n    /* margin-right: 10px; */\n}\n.dsa-login-container{\n    height: 605px;\n    align-items: center;\n}\n.error-message {\n    color: red !important;\n    font-size: 0.9em;\n    margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
