// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dsa-loan-customer-container {
    background-color: #f9f9f9;
    padding: 20px;
}

.dsa-loan-customer-content {
    margin: 0 auto;
    /* max-width: 800px; */
}

.section-title {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
}

.table {
    width: 100%;
    /* margin-bottom: 20px; */
    color: #333;
}

.table th,
.table td {
    text-align: center;
    
}

.table-responsive {
    overflow-x: auto;
}

.apply-loan-button {
    /* margin-top: 10px; */
    margin-bottom: 20px;
    display: block;
    /* width: 100%; */
}

.modal-dialog {
    max-width: 600px;
}

.form-select,
.form-control {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Customer_Dashboard/DSA_Loan_Customer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,WAAW;AACf;;AAEA;;IAEI,kBAAkB;;AAEtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;AACf","sourcesContent":[".dsa-loan-customer-container {\n    background-color: #f9f9f9;\n    padding: 20px;\n}\n\n.dsa-loan-customer-content {\n    margin: 0 auto;\n    /* max-width: 800px; */\n}\n\n.section-title {\n    margin-top: 20px;\n    margin-bottom: 10px;\n    text-align: center;\n    color: #333;\n}\n\n.table {\n    width: 100%;\n    /* margin-bottom: 20px; */\n    color: #333;\n}\n\n.table th,\n.table td {\n    text-align: center;\n    \n}\n\n.table-responsive {\n    overflow-x: auto;\n}\n\n.apply-loan-button {\n    /* margin-top: 10px; */\n    margin-bottom: 20px;\n    display: block;\n    /* width: 100%; */\n}\n\n.modal-dialog {\n    max-width: 600px;\n}\n\n.form-select,\n.form-control {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
